import React, { useState } from "react";
import "./Signout.css";

import { Modal, Button } from "react-bootstrap";
import { Logout } from "../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import { appserveraddr } from "../../Services/EndPoints";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";

const Signout = () => {
  const [isShow, invokeModal] = useState(false);
   const { keycloak } = useKeycloak();
   const { collapsed } = LayoutState();
   const hideModal = () => {
    invokeModal(false);
  };
  return (
    <div className="sidebar1">
      <div className="sidebar__content1" onClick={invokeModal}>
        <span>
          <Logout width="1.1em" height="1.1em" />
          <span className="signout_position">Sign Out</span>
        </span>
      </div>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={hideModal}></Modal.Header>
        <Modal.Body>
          <div className="container  exit_btns_container">
            <div className="row mt-2 mb-3">
              <div className="col-6">
                <div
                  className={`exit_btns sidebar__bottom ${
                    collapsed && " logout"
                  }`}
                  onClick={() => {
                    localStorage.clear();
                    keycloak.logout({ redirectUri: appserveraddr });
                  }}
                >
                  Logout
                </div>
              </div>
              <div className="col-6">
                This will log you out from all the applications.
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <div
                  className="exit_btns"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_COMMON_DASHBOARD_URL;
                  }}
                >
                  Exit Current
                </div>
              </div>
              <div className="col-6">
                This will exit your current application.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Signout;
