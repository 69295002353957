import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LayoutContext from "./Context/LayoutContext/LayoutContext";
import MapMarkerContext from "./Context/MapMarkerContext/MapMarkerContext";
import AppContext from "./Context/AppContext/AppContext";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactKeycloakProvider
    initOptions={{ onLoad: "login-required" }}
    authClient={keycloak}
  >
    <LayoutContext>
      <AppContext>
        <MapMarkerContext>
          <BrowserRouter basename="/report">
            <App />
          </BrowserRouter>
        </MapMarkerContext>
      </AppContext>
    </LayoutContext>
  </ReactKeycloakProvider>
);
