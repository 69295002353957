export const dmsUrl = process.env.REACT_APP_DMS_CORE_URL;
export const dmsGisUrl = process.env.REACT_APP_DMS_GIS_CORE_URL;

// export const baseUrl = process.env.REACT_APP_LMS_BASE_URL;
export const baseUrl = process.env.REACT_APP_ASSET_BASE_URL;
export const keycloakbaseurl = process.env.REACT_APP_URM_BASE_URL;
export const appserveraddr = process.env.REACT_APP_APP_SERVER_ADDRESS;
export const urmservice = process.env.REACT_APP_URM_SERVICE;
export const serviceBaseURL = process.env.REACT_APP_DATA_SERVICE;
export const dataservice = process.env.REACT_APP_DATA_SERVICE;
export const gisserviceBaseURL = process.env.REACT_APP_GIS_SERVICE

//User Management
export const createRole = "/role/create";
export const updateRole = "/role/update";
export const deleteRole = "/role/delete";
export const getAllRole = "/role/getall";
export const deletePermissionsFromRole = "/role/deletePermissionsFromRole/";

export const getallUsersByRealm = "/urm/admin/realms/{REALM}/users";
export const getAdminAuthTokenByRealm = "/urm/realms/{REALM}/protocol/openid-connect/token";
export const createUserintoRealm = "/urm/admin/realms/{REALM}/users";
export const getallRolesByRealm = "/urm/admin/realms/{REALM}/roles";
export const addPermissionsToRole = "/role/addPermissionsToRole/";
export const createRoleintoRealm = "/urm/admin/realms/{REALM}/roles";

export const getRoleDataByRoleNameURL = "/urm/admin/realms/{REALM}/roles/"
export const getUserDataByEmailAttr = "/urm/admin/realms/{REALM}/users?email={EMAIL}&exact=true"
export const assignRolesToUser = "/urm/admin/realms/{REALM}/users/{USER_ID}/role-mappings/realm"
export const deleteUserByRealm = "/urm/admin/realms/{REALM}/users/{USER_ID}"
export const userImageUpload = "/user"
export const mediaFileLink = "/media/file"
export const findUsersById = "/user/findUsersById"

// ---------------------------------------------------------------------------------

//User Management
export const updateUserStatusByUserId = "/urm/admin/realms/{REALM}/users/{USER_ID}"
export const getUserByUserId = "/urm/admin/realms/{REALM}/users/"
export const getRoleById = "/urm/admin/realms/{REALM}/roles-by-id/"
export const getRolesByUserId = "/urm/admin/realms/{REALM}/users/{USER_ID}/role-mappings"
export const updateUserByUserId = "/urm/admin/realms/{REALM}/users/"
export const deletRoleById = "/urm/admin/realms/{REALM}/roles-by-id/"
export const getAllPermissions = "/permission/getall";
export const getUsersFromApp = "/user/getall";

//Resource Management
export const resourceListURL = "/resource/getall";
export const createResourceURL = "/resource/create";
export const updateResourceURL = "/resource/update";
export const deleteResourcebyId = "/resource/delete";
export const findResourcesById = "/resource/findResourcesById";


export const regionListURL = "/region/getall";
export const minifiedRegionListURL = "/region/getMinifiedRegionList";


export const resourceTypListURL = "/config/getConfigsByCategoryName/RSRC_KND";
export const agencyListURL = "/agency/getall";
export const createAgencyURL = "/agency/create";
export const agencyTypeListURL = "/config/getConfigsByCategoryName/AGNCY_TYPE";
export const masterDataAttrTypeListURL = "/config/getConfigsByCategoryName/MASTER_DATA_TYPE";
export const getConfigsByCategoryName = "/config/getConfigsByCategoryName/";
export const createConfig = "/config/create";
export const hierarchylevelListURL = "/region/getall";
export const createResource = "/resource/create";
export const createRegion = "/region/create"
export const assignRoleToUser = "/user/create"
export const updateUserInAppURL = "/user/update"


//Region Management
export const findRegionById = "/region/findRegionsById";
export const updateRegion = "/region/update";
export const deleteRegionURL = "/region/delete";

//AgencyManagement
export const agencyTypeURL = "/config/getConfigsByCategoryName/AGNC_TYP";
export const findAgenciesById = "/agency/findAgenciesById";
export const updateAgencyURL ="/agency/update";
export const deleteAgencyURL = "/agency/delete";


//ContactManagement
export const createContact = "/contact/create";
export const getContacts = "/contact/getall";
export const updateContact =  "/contact/update";
export const deleteContactURL = "/contact/delete";

//FacilityManagement
export const createFacility = "/facility/create";
export const getFacilities = "/facility/getall";
export const creategisFacilityEntry = "/create/facility/"
export const updateFacility = "/facility/update"
export const updategisFacilityEntry = "/update/facility"
export const findFacilityById = "/facility/findFacilitiesById";
export const getFacilityTypeURL = "/config/getConfigsByCategoryName/FCLTY_TYP";
export const deleteFacilityURL = "/facility/delete";


//Master Resource Data Management
export const createMasterResource = "/master_resource/create"
export const getMasterResourceListURL= "/master_resource/getall"
export const resourceActivityListURL = "/master_resource/getResourceActivties";
export const resourceCatListURL = "/master_resource/getResourceCategories/";
export const resourceNameListURL = "/master_resource/getResourceNames/";
export const getMasterResourceByIdURL = "/master_resource/findMasterResourcesById";


//Job Title Management
export const createJobTitle = "/job_title/create";
export const getJobTitle = "/job_title/getall";

//Contact Management
export const findContactsById = "/contact/findContactsById";

//Task Management
export const getTaskConfigsURL = "/config/getConfigsByCategoryNames";
export const createTaskURL  = "/task/create";
export const getTaskListURL  = "/task/getall";
export const updateTaskURL = "/task/update";
export const deleteTaskURL = "/task/delete"
export const findTaskById  = "/task/findTasksById";

//SOP Management
export const createMasterSOPURL  = "/sop_master/create" 
export const updateMasterSOPURL = "/sop_master/update" 
export const getMasterSOPListURL  = "/sop_master/getMasterSOPList" 

//My Task Management
export const getMyTaskListURL = "/task/findTasksByUser" 
export const claimMytask = "/task/claimMyTask" 
export const completeTask = "/task/completeTask" 

//For Builder
export const createFormStructure = "/formbuilder/create" 
export const getFormStructureURL = "/formbuilder/view" 
export const gtFormDataByFormNameURL = "/formbuilderdata/getFormdataByFormName/"
export const getStoredFormTemplates = "/formbuilder/getStoredFormTemplates"

//For Builder Data
export const createFormDataViaBuilder = "/formbuilderdata/create"
