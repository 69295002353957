let dashboardconfig={"jsonstr_menu":{}}

export function setMenuInfo(key,value){
    //console.log('Config'+JSON.stringify(dashboardconfig));
    dashboardconfig['jsonstr_menu'][key]=value;
    return true;
}

export function getDashboardInfo(){
    return dashboardconfig;
}

export function clearDashboardInfo(){
    dashboardconfig={"jsonstr_menu":{}};
}


