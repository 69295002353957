let reportData={}

export function addReportData(reportId,data){
    reportData[reportId]=data;
}
export function getReportData(reportId,data){
    return reportData[reportId];
}
export function cleanReportData(){
    return reportData={};
}