import React,{createContext, useContext} from 'react';
import './App.css';
import Layout from "./Layout/Layout"

const ThemeContext = createContext({});

function App() {
  document.documentElement.setAttribute("data-bs-theme", "dark");
  return (
    <div>
      <Layout/>
    </div>

  );
}

export default App;
