import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import logo from "../../Assets/Images/logo_white.png";
import { NavLink } from "react-router-dom";
import navLinks from "../../JsonData/navLinks";
import logout from "../../Assets/Icons/logout.svg";
import Axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import Config from "../../JsonData/config";
import { appserveraddr } from "../../Services/EndPoints";
import { dmsUrl, dmsGisUrl } from "../../Services/EndPoints";
import { Modal } from "react-bootstrap";
import {
  MyTasks,
  UsersIcon,
  DevicesIcon,
  GisManagement,
  ReportsIcon,
  Awareness,
  Execution,
} from "../../Components/IconComponents";
import SvgAwareness from "../../Components/IconComponents/Awareness";
import SvgExecution from "../../Components/IconComponents/Execution";
const height = "1.2em";
const width = "1.2em";

const Sidebar = ({ collapsed, toggle }) => {
  const [menus, setMenus] = useState([]);
  const [isShow, invokeModal] = React.useState(false);
  const { keycloak } = useKeycloak();
  const showModal = () => {
    invokeModal(true);
  };
  const hideModal = () => {
    invokeModal(false);
  };

  useEffect(() => {
    fetchMenus();
  }, []);
  useEffect(() => {
    console.log(menus);
  }, [menus]);
  const fetchMenus = async () => {
    const url = Config.baseurl + Config.menu;
    console.log("menu url >> " + url);
    const response = await Axios(url);
    setMenus(response.data);
    //setMenus(navLinks);
  };
  return (
    <div className={`${collapsed ? "sb-collapsed" : "sidebar"}`}>
      <div
        className={`mt-3 sidebar__top ${
          collapsed ? " sidebar__top--collapsed" : null
        }`}
        onClick={toggle}
      >
        <img
          src={logo}
          alt="Prutech-logo"
          className={`${collapsed ? "logo-collapsed" : "logo"}`}
        />
      </div>
      <div className="sidebar__content">
        <div className="menu">
          <ul
            className={`nav__list ${collapsed ? "nav__list--collapsed" : null}`}
          >
            <span>
              <MyTasks width={width} height={height} />
              <a href={dmsUrl + "/my-tasks"} className="sidebaritems_style">
                My Tasks
              </a>
            </span>
            <span>
              <UsersIcon width={width} height={height} />
              <a
                href={dmsUrl + "/masterdata-table"}
                className="sidebaritems_style"
              >
                Administrative Activities
              </a>
            </span>
            <span>
              <DevicesIcon width={width} height={height} />
              <a href={dmsUrl + "/planning"} className="sidebaritems_style">
                Planning
              </a>
            </span>
            <span>
              <Awareness width={width} height={height} />
              <a
                href={dmsGisUrl + "/gis/gismanagement"}
                className="sidebaritems_style"
              >
                Situational Awareness
              </a>
            </span>
            <span>
              <Execution width={width} height={height} />
              <a
                href={dmsGisUrl + "/gis/sopinstancelist"}
                className="sidebaritems_style"
              >
                SOP Execution
              </a>
            </span>
            <span>
              <GisManagement width={width} height={height} />
              <a
                href={dmsGisUrl + "/gis/gismanagement"}
                className="sidebaritems_style"
              >
                Response
              </a>
            </span>

            <li className="nav__item">
              <NavLink
                to="/builder"
                onClick={() => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);
                }}
                className={({ isActive }) =>
                  isActive
                    ? `nav__link ${
                        !collapsed ? "nav__active" : "nav__active--collapsed"
                      }`
                    : "nav__link "
                }
              >
                <span>
                  <i
                    style={{ marginRight: !collapsed ? "10px" : "0px" }}
                    className="fa-solid fa-chart-line"
                  ></i>
                  {collapsed ? null : "Report Builder"}
                </span>
              </NavLink>

              <ul>
                {menus &&
                  menus.map((item, index) => {
                    return (
                      <li className="nav__item mt-2" key={index}>
                        <NavLink
                          to={"/dashboard/" + item.reportId}
                          onClick={() => {
                            setTimeout(() => {
                              window.location.reload();
                            }, 100);
                          }}
                          className={({ isActive }) =>
                            isActive
                              ? `nav__link ${
                                  !collapsed
                                    ? "nav__active"
                                    : "nav__active--collapsed"
                                }`
                              : "nav__link "
                          }
                        >
                          <span>
                            <i
                              style={{
                                marginRight: !collapsed ? "10px" : "0px",
                              }}
                              className={item.icon}
                            ></i>
                            {collapsed ? null : item.display}
                          </span>
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <span>
              <ReportsIcon width={width} height={height} />
              <a
                href={dmsUrl + "/reportmanagement"}
                className="sidebaritems_style"
              >
                Ad hoc Reports
              </a>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;