import * as React from "react";
const SvgDevicesIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#devices-icon_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.75 2.833h-8.5c-.782 0-1.417.635-1.417 1.417v8.5c0 .782.635 1.417 1.417 1.417h8.5c.782 0 1.417-.635 1.417-1.417v-8.5c0-.782-.635-1.417-1.417-1.417Z" />
      <path d="M10.625 6.375h-4.25v4.25h4.25v-4.25ZM6.375.708v2.125M10.625.708v2.125M6.375 14.167v2.125M10.625 14.167v2.125M14.167 6.375h2.125M14.167 9.917h2.125M.708 6.375h2.125M.708 9.917h2.125" />
    </g>
    <defs>
      <clipPath id="devices-icon_svg__a">
        <path fill="currentColor" d="M0 0h17v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDevicesIcon;
