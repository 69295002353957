import * as React from "react";
const SvgReportsIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.557 14.914 9.93.048a1.419 1.419 0 0 0 1.427-1.411l.048-9.931a1.419 1.419 0 0 0-1.412-1.426l-9.93-.049a1.419 1.419 0 0 0-1.426 1.412l-.049 9.931a1.419 1.419 0 0 0 1.412 1.426Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.955 12.107 2.128.01.031-6.383-2.128-.01-.03 6.383ZM4.99 12.083l2.128.01.017-3.546-2.128-.01-.017 3.546Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReportsIcon;
