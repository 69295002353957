import * as React from "react";
const SvgBurgerIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.504 13.863H6M15.504 9.677H6M9.627 5.5H6" />
    </g>
  </svg>
);
export default SvgBurgerIcon;
