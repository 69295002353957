import * as React from "react";
const SvgUsersIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.794 11.133c2.613 0 4.846.396 4.846 1.98 0 1.582-2.218 1.99-4.846 1.99-2.614 0-4.846-.393-4.846-1.977 0-1.583 2.217-1.993 4.846-1.993ZM6.794 8.874a3.108 3.108 0 0 1-3.107-3.11 3.108 3.108 0 1 1 6.213 0 3.097 3.097 0 0 1-3.084 3.11h-.022Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.675 8.067a2.34 2.34 0 0 0 2.01-2.312 2.34 2.34 0 0 0-1.957-2.309M13.172 10.797c1.098.164 1.865.55 1.865 1.343 0 .547-.36.901-.945 1.123"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgUsersIcon;
