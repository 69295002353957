export const ChartSettings = {
  "line": {
    "option": {
      xAxis: {
        type: 'category',
        data:[],
        splitLine: {
          show: false
        }
      },
      tooltip: {

      },
      grid: {
        bottom:'20%'
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      legend: {
        data:[],
        textStyle: {
          color: '#fff'
        }
      }
    },
    "series": {
      type: "line",
      smooth: true,
      markPoint: {
        data: [
          { type: 'max', name: 'Max' }
        ]
      }
    }
  },
  "bar": {
    "option": {
      xAxis: {
        type: 'category',
        data:[],
        splitLine: {
          show: false
        }
      },
      tooltip: {

      },
      grid: {
        bottom: "20%"
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      legend: {
        data:[],
        textStyle: {
          color: '#fff'
        }
      }
    },
    "series": {
      type: "bar",
      barWidth: 15,
      markPoint: {
        data: [
          { type: 'max', name: 'Max' }
        ]
      }
    }
  },
  "doughnut": {
    "option": {
      tooltip: {
        trigger: 'item',
        confine :true
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: '#fff',
        }
    },
    grid: {
      bottom: "40%"
    }
  },
    "series": {
      type: 'pie',
      radius: ['40%', '70%'],
      left: "40%",
      
      labelLine: {
        show: false
      },
      label: {
        show: false
      }
    }
  },
  "pie": {
    "option": {
      tooltip: {
        trigger: 'item',
        confine :true
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: '#fff'
        }
    },
    grid: {
      bottom: "10%"
    }
    },
    "series": {
      type: 'pie',
      radius: '70%',
      left: "40%",
     
      labelLine: {
        show: false
      },
      label: {
        show: false
      }
    }
  }
};

export const getchartdata=(type,data)=>{
  const charttype = type.toLowerCase();
  let option =JSON.parse(JSON.stringify(ChartSettings[charttype]['option']));
}