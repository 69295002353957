import React, { useEffect, useState } from "react";
import "./WidgetConfig.css";
import widgetcatalogimg from "../Assets/Images/widgetcatalog.png";
import linechartimg from "../Assets/Images/linechart.png";
import datatableimg from "../Assets/Images/datatable.png";
import barchrtimg from "../Assets/Images/barchart.png";
import doughnutchartimg from "../Assets/Images/doughnutchart.png";
import piechartimg from "../Assets/Images/piechart.png";
const WidgetConfig = (prop) => {
    const [index, setIndex] = useState(prop.index);
    const [widgetType, setWidgetType] = useState(widgetcatalogimg);
    const [isLine, setIsLine] = useState(false);
    const [isBar, setIsBar] = useState(false);
    const [isDoughnut, setIsDoughnut] = useState(false);
    const [isDatatable, setDatatable] = useState(false);
    let radioId = 'radio'+index;
    const selectWidgetType = (type) => {
        console.log(type);
        setIsLine(false);
        setIsBar(false);
        setIsDoughnut(false);
        setDatatable(false);
        if(type=='line'){
            setIsLine(true);
        }else if(type=='bar'){
            setIsBar(true);
        }else if(type=='bar'){
            setIsBar(true);
        }else if(type=='doughnut'){
            setIsDoughnut(true);
        }else if(type=='datatable'){
            setDatatable(true);
        }
        // setIsLine(current => !current);
    }
    useEffect(() => {
        console.log('Widget type...'+prop.type);
        if(prop.type==='chart:bar'){
            setWidgetType(barchrtimg);
        }else if(prop.type==='chart:line'){
            setWidgetType(linechartimg);
        }else if(prop.type==='chart:doughnut'){
            setWidgetType(doughnutchartimg);
        }else if(prop.type==='chart:pie'){
            setWidgetType(piechartimg);
        }else if(prop.type==='table:datatable'){
            setWidgetType(datatableimg);
        }
    }, [prop]);

    return (
        <>
            {/* <div className="row">
                <div className="col-md-6 col-lg-6">
                    <div className='card' >

                        <img src={linechartimg} className={isLine ? 'selected' : ''}  />
                        <div className="card-body widgettype">
                            <div className="form-check form-switch">
                                <input className="form-check-input" name={radioId} id={radioId} type="radio"  onClick={() => selectWidgetType('line')}/>
                                <label className="form-check-label" for="flexSwitchCheckChecked">Select</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6">
                    <div className="card">

                        <img src={barchrtimg} className={isBar ? 'selected' : ''} alt="thumbnail"  />

                        <div className="card-body widgettype">
                        <div className="form-check form-switch">
                                <input className="form-check-input" type="radio" name={radioId} id={radioId} onClick={() => selectWidgetType('bar')}/>
                                <label className="form-check-label" for="flexSwitchCheckChecked">Select</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6">
                    <div className="card">

                        <img src={doughnutchartimg} className={isDoughnut ? 'selected' : ''} alt="thumbnail"  />
                        <div className="card-body widgettype">
                        <div className="form-check form-switch">
                                <input className="form-check-input" type="radio" name={radioId} id={radioId} onClick={() => selectWidgetType('doughnut')}/>
                                <label className="form-check-label" for="flexSwitchCheckChecked">Select</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6">
                    <div className="card">
                        <img src={datatableimg} className={isDatatable ? 'selected' : ''} alt="thumbnail"   />
                        <div className="card-body widgettype">
                        <div className="form-check form-switch">
                                <input className="form-check-input" type="radio" name={radioId} id={radioId} onClick={() => selectWidgetType('datatable')}/>
                                <label className="form-check-label" for="flexSwitchCheckChecked">Select</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
             <div className="container">
            <div className="row">
                <div className="col-md-12 mt-1col-lg-12">
                    <img src={widgetType} className="widgetimg" alt="image"/>
                </div>

            </div>
        </div>
        </>
    );

}
export default WidgetConfig;