import React from "react";

function SOPInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90.047"
      height="77.406"
      data-name="Layer 1"
      viewBox="0 0 655.047 473.406"
    >
      <path
        fill="#2f2e41"
        d="M514.834 42.597c-1.655-12.137-3.73-25.167-12.29-33.928a28.786 28.786 0 00-41.59.43c-6.231 6.647-8.983 15.981-9.235 25.089s1.777 18.114 4.027 26.943a83.043 83.043 0 0034.658-6.821 55.842 55.842 0 018.31-3.263c2.887-.735 4.946 1.115 7.632 2.404l1.474-2.823c1.207 2.246 4.945 1.58 6.3-.58 1.356-2.159 1.059-4.925.714-7.451z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M465.097 93.84l3.448-17.613-25.853-11.192-46.22-22.143a10.27 10.27 0 10-7.445 11.5l44.008 27.158z"
      ></path>
      <path
        fill="#f9a008"
        d="M447.742 90.86l18.725-19.141.37.112c.233.071 23.498 7.164 38.33 14.37a12.634 12.634 0 016.969 8.04 13.25 13.25 0 01-4.156 13.92 14.173 14.173 0 01-15.159 2.413z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M447.684 175.017L500.338 189.087 510.971 139.346 462.642 124.387 447.684 175.017z"
      ></path>
      <path
        fill="#f9a008"
        d="M506.232 161.516l-51.437-11.829-9.85-23.872a18.263 18.263 0 016.587-18.614l18.793-15.866 10.074-12.87-1.097-6.82a5.908 5.908 0 013.938-6.433l13.427-4.815a6.248 6.248 0 017.881 3.278l1.423 3.293s6.072 2.857 9.495 4.464a13.741 13.741 0 017.43 8.418 69.66 69.66 0 01-3.95 51.152z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M48.791 414.93l-24.87-6.655a83.066 83.066 0 01-1.235-39.948c10.85 21.448 39.99 25.492 57.252 42.217a49.964 49.964 0 0114.659 40.752l5.957 17.26A83.724 83.724 0 0138.1 436.714a80.874 80.874 0 01-10.285-17.488c10.406-1.524 20.976-4.296 20.976-4.296z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M357.77 473.406H114.013a24.668 24.668 0 01-24.64-24.64V126.003a24.668 24.668 0 0124.64-24.64H357.77a24.668 24.668 0 0124.64 24.64v322.763a24.668 24.668 0 01-24.64 24.64z"
      ></path>
      <path
        fill="#fff"
        d="M264.735 453.427H131.147a22.461 22.461 0 01-22.435-22.436V143.777a22.46 22.46 0 0122.435-22.435h209.489a22.46 22.46 0 0122.435 22.435v211.314a98.448 98.448 0 01-98.336 98.336z"
      ></path>
      <path
        fill="#f9a008"
        d="M319.738 176.481H152.045a5.469 5.469 0 11-.014-10.938h167.707a5.469 5.469 0 01.014 10.938zM319.738 198.092H152.045a5.47 5.47 0 010-10.938h167.693a5.47 5.47 0 010 10.938zM224.736 219.703h-72.69a5.47 5.47 0 110-10.938h72.69a5.47 5.47 0 010 10.938z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M319.738 264.964H152.045a5.469 5.469 0 11-.014-10.938h167.707a5.469 5.469 0 01.014 10.938zM319.738 286.575H152.045a5.47 5.47 0 010-10.938h167.693a5.47 5.47 0 010 10.938zM224.736 308.186h-72.69a5.47 5.47 0 110-10.939h72.69a5.47 5.47 0 110 10.939zM319.738 353.447H152.045a5.469 5.469 0 11-.014-10.938h167.707a5.469 5.469 0 01.014 10.938zM319.738 375.058H152.045a5.47 5.47 0 010-10.939h167.693a5.47 5.47 0 010 10.939zM224.736 396.669h-72.69a5.47 5.47 0 010-10.939h72.69a5.47 5.47 0 010 10.939z"
      ></path>
      <circle cx="370.373" cy="107.64" r="71.1" fill="#f9a008"></circle>
      <path
        fill="#231f20"
        d="M370.373 168.89a71.09 71.09 0 01-70.904-66.094 71.92 71.92 0 00-.195 5.004 71.1 71.1 0 00142.199 0c0-1.684-.08-3.35-.195-5.004a71.09 71.09 0 01-70.905 66.095z"
        opacity="0.16"
      ></path>
      <path
        fill="#fff"
        d="M370.374 142.385a8.915 8.915 0 01-8.905-8.904V81.96a8.904 8.904 0 1117.808 0v51.52a8.914 8.914 0 01-8.903 8.905z"
      ></path>
      <path
        fill="#fff"
        d="M335.709 107.72a8.915 8.915 0 018.904-8.904h51.52a8.904 8.904 0 110 17.808h-51.52a8.914 8.914 0 01-8.904-8.903z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M477.254 122.828l-15.13-9.655-19.878 19.963-37.522 34.909a10.27 10.27 0 107.977 11.138l41.38-31.015z"
      ></path>
      <path
        fill="#f9a008"
        d="M468.13 137.888l-10.962-24.43.24-.304c.151-.19 15.267-19.247 27.402-30.411a12.634 12.634 0 0110.031-3.544 13.257 13.257 0 0111.434 8.962 14.171 14.171 0 01-3.302 14.99z"
      ></path>
      <circle cx="478.032" cy="33.8" r="23.786" fill="#ffb8b8"></circle>
      <path
        fill="#ffb8b8"
        d="M441.077 456.369L431.244 456.369 426.564 418.442 441.077 418.442 441.077 456.369z"
      ></path>
      <path
        fill="#2f2e41"
        d="M446.267 473.406h-7.052l-1.259-6.658-3.223 6.658H416.03a4.204 4.204 0 01-2.389-7.664l14.935-10.315v-6.73l15.71.937z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M564.317 448.015L555.181 451.652 536.806 418.145 550.29 412.777 564.317 448.015z"
      ></path>
      <path
        fill="#2f2e41"
        d="M575.44 461.925l-6.552 2.608-3.631-5.72-.533 7.377-17.377 6.917a4.204 4.204 0 01-5.054-6.236l10.062-15.108-2.49-6.253 14.943-4.939zM444.87 438.946h-20.34l5.622-95.56-4.11-64.771.009-.052 15.326-92.847a39.212 39.212 0 019.396-19.694l.186-.21 51.292 12.667 5.157 11.76c17.61 10.472 23.17 69.532 23.399 72.047l7.455 73.624 18.236 90.234-12.842 6.915-.215-.428-40.543-80.62-25.604-85.018-16.397 76.518zM507.489 34.92c-.842-5.53-1.721-11.104-3.698-16.31s-5.165-10.082-9.737-13.031c-7.24-4.67-16.612-3.769-24.728-1.083-6.276 2.077-12.403 5.234-16.83 10.31-4.426 5.075-6.912 12.317-5.266 18.953l25.973-6.136-.948.67a20.91 20.91 0 0111.16 14.544 21.666 21.666 0 01-4.57 17.994l17.713-6.284c3.644-1.293 7.545-2.782 9.706-6.099 2.506-3.844 1.924-8.94 1.225-13.528z"
      ></path>
      <path
        fill="#ccc"
        d="M273.723 686.474h652.554a1.247 1.247 0 000-2.494H273.723a1.247 1.247 0 000 2.494z"
        data-name="Path 2960"
        transform="translate(-272.476 -213.297)"
      ></path>
    </svg>
  );
}

export default SOPInfoIcon;
