import React, { useEffect, useState } from "react";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "./ReportBodyRender.css";
import report from "../../JsonData/reportLayout";
import RenderChart from "../../ReportUtils/Chartlibrary"
import RenderDataTable from "../../ReportUtils/DataTable";
import { useParams } from "react-router-dom";
import Config from "../../JsonData/config";
import Axios from 'axios';
import RenderTable from "../../ReportUtils/Table";
import ReactErrorBoundary from "../../Services/ReactErrorBoundary";
import * as MaterialDesign from "react-icons/md";
import { cleanReportData, getReportData } from "../../ReportUtils/ReportData";
import * as XLSX from "xlsx";
import { usePDF } from 'react-to-pdf';
const ReportBodyRender = () => {
  cleanReportData();
  const { reportId } = useParams()
  const [isLoading, setLoading] = useState(true);
  const [grid, setGrid] = useState(null);
  const [data, setData] = useState();
  const[autoRef,setAutoRef] =useState(new Date().getTime());
  const { toPDF, targetRef } = usePDF({filename: 'report.pdf'});
  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const getType = (widgetId, height) => {
    //console.log('widgetId >>' + widgetId);
    const widgetconfig = data.widget[widgetId];
    //console.log('widget type ' + widgetconfig['widgetconfig']['type']);
    if (widgetconfig) {
      if (widgetconfig['widgetconfig']['type'].startsWith('chart')) {
        return <RenderChart chartconfig={widgetconfig} widgetId={widgetId} height={height} />;
      } else if (widgetconfig['widgetconfig']['type'].startsWith('table')) {
        return <RenderDataTable tableConfig={widgetconfig} widgetId={widgetId} height={height} />;
      }
    } else {
      return "Not Available"
    }
  }
  const getTitle = (widgetId) => {
    const widgetconfig = data.widget[widgetId];
    if (widgetconfig) {
      return widgetconfig.title;
    } else {
      return "NA";
    }
  }
  const downloadXls = async (widgetId, name) => {
    const widgetconfig = data.widget[widgetId];
    if (widgetconfig['serviceconfig']) {
      const url = Config.baseurl + '/api/getDataForConfig';
      let postdata = { 'serviceconfig': widgetconfig['serviceconfig'] };
      const response = await Axios.post(url, postdata);
      let reportdata = response.data.data;
      if (reportdata[0]) {
        let headers = [];
        for (var key in reportdata[0]) {
          headers.push(key);
        }
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(reportdata);
        XLSX.utils.book_append_sheet(workbook, worksheet, name);
        XLSX.utils.sheet_add_aoa(worksheet, [headers]);
        XLSX.writeFile(workbook, name + ".xlsx", { compression: true });
      }
    }
  }
  const fetchDashboard = async () => {
    const url = Config.baseurl + Config.dashboard + "/" + reportId;
    console.log("dashboard url >> " + url);
    const response = await Axios(url);
    console.log(response.data);
    setData(response.data);
    setLoading(false);
    setTimeout(() => {
      let gridobj = GridStack.init({ disableResize: true, disableDrag: true, margin: 5 });
      setGrid(gridobj);
    }, 1000);
  }
  useEffect(() => {
    console.log('Report ID >>' + reportId);
    fetchDashboard();
    const interval = setInterval(() => {
      //setAutoRef(new Date().getTime());
    }, 20000);
  }, [reportId,autoRef]);
  if (isLoading) {
    return <div className="header">Loading...</div>;
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <MaterialDesign.MdCameraAlt className="screenshoticon float-end" onClick={() => toPDF()}></MaterialDesign.MdCameraAlt>
        </div>
      </div>
      <div className="grid-stack" ref={targetRef}>
        {data && data.layout.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-stack-item ui-draggable ui-resizable"
              gs-x={item.x}
              gs-y={item.y}
              gs-w={item.w}
              gs-h={item.h}>
              <div className="grid-stack-item-content grid-item">
                <div id={"widget" + item.id} style={{ height: "100%" }}>
                  <div className="card">
                    <div className="card-header">
                      {getTitle(item.id)}
                      <div className="widgetactionreport">
                        <MaterialDesign.MdDownloadForOffline className="widgetactioniconreport" onClick={() => downloadXls(item.id, getTitle(item.id))}></MaterialDesign.MdDownloadForOffline>
                      </div>
                    </div>
                    <div className="card-body" style={{ height: "100%", width: "100%" }}>
                      <ReactErrorBoundary>
                        {getType(item.id, item.h)}
                      </ReactErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
}
export default ReportBodyRender;
