import React, { useEffect, useState, useRef } from "react";
import { GridStack } from "gridstack";
import { useParams } from "react-router-dom";
import "gridstack/dist/gridstack.min.css";
import "./Builder.css";
import Axios from 'axios';
import * as MaterialDesign from "react-icons/md"
import WidgetConfig from "./WidgetConfig";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select, { components } from "react-select";
import linechartimg from "../Assets/Images/linechart.png";
import datatableimg from "../Assets/Images/datatable.png";
import barchrtimg from "../Assets/Images/barchart.png";
import doughnutchartimg from "../Assets/Images/doughnutchart.png";
import { setMenuInfo, clearDashboardInfo, getDashboardInfo } from "./Metadata";
import Config from "../JsonData/config";;
import Modal from 'react-bootstrap/Modal';
import ReactErrorBoundary from "../Services/ReactErrorBoundary";

const widgets = [
    { value: "chart:bar", label: "Bar Chart", icon: barchrtimg },
    { value: "chart:line", label: "Line Chart", icon: linechartimg },
    { value: "chart:doughnut", label: "Doughnut Chart", icon: doughnutchartimg },
    { value: "table:datatable", label: "Data Table", icon: datatableimg }
];

const dashboardicon = [
    { value: "fa-solid fa-chart-simple", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-simple' },
    { value: "fa-solid fa-chart-pie", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-pie' },
    { value: "fa-solid fa-chart-line", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-line' },
    { value: "fa-brands fa-windows", text: 'Dashboard Menu Icon', icon: 'fa-brands fa-windows' },
    { value: "fa-solid fa-sliders", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-sliders' },
    { value: "fa-solid fa-layer-group", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-layer-group' },
    { value: "fa-solid fa-table", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-table' },
    { value: "fa-solid fa-headphones", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-headphones' }
];

const dashboardiconOption = (props) => (
    <components.Option {...props} className="widget-option">
        <span className={props.data.icon}></span>
        {props.data.icon}
    </components.Option>
);

const widgetOption = (props) => (
    <components.Option {...props} className="widget-option">
        <img src={props.data.icon} alt="logo" className="widget-logo" />
        {props.data.label}
    </components.Option>
);

const LoadEditBuilder = () => {
    const { reportId } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [grid, setGrid] = useState(null);
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedEditReport, setSelectedEditReport] = useState(null);
    const [allreports, setAllreports] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [widgetTitle, setWidgetTitle] = useState(null);
    const [widgetQuery, setWidgetQuery] = useState(null);
    const [dashboardname, setDashboardname] = useState(null);
    const [selectedDashboardicon, setSelectedDashboardicon] = useState(dashboardicon[0]);
    const [selectedWidget, setSelectedWidget] = useState(widgets[0]);
    useEffect(() => {
        console.log('Report ID >>' + reportId);
        fetchMenus();
        fetchDashboard();
    }, [reportId]);

    const handleReportChage = (value) => {
        setSelectedEditReport(value);

    };

    const changeDashboard = event => {
        let dashboardname = event.target.value;
        setDashboardname(dashboardname);
    };
    const handleIconChange = (value) => {
        setSelectedDashboardicon(value);
    };

    const changeWidgetTitle = event => {
        setWidgetTitle(event.target.value);
    };

    const changeWidgetQuery = event => {
        setWidgetQuery(event.target.value)
    };

    const handleChange = (value) => {
        setSelectedWidget(value);
    };

    const handleCanvasShow = (index) => {
        console.log('Current Index >>' + index);
        setCurrentIndex(index);
        setShow(true)
    };
    const getSelectedWidget = (curindex) => {
        let selwidget = 0;
        const widget = data.widget?data.widget[curindex]:null;
        if(widget!=null){
        const value = widget['widgetconfig']['type'];
        widgets.forEach(function(element,index) {
            if (element['value'] === value) {
                selwidget = index;
            }
        });
    }
        return widgets[selwidget];
    }
    const fetchMenus = async () => {
        const url = Config.baseurl + Config.menu;
        console.log("menu url >> " + url);
        const response = await Axios(url);
        let reports = [];
        response.data.forEach(element => {
            reports.push({ value: element["reportId"], text: element["display"], icon: element["icon"] })
        });
        setAllreports(reports);
    }

    const fetchDashboard = async () => {
        const url = Config.baseurl + Config.dashboard + "/" + reportId;
        console.log("dashboard url >> " + url);
        const response = await Axios(url);
        setData(response.data);
        setLoading(false);
        setTimeout(() => {
            let gridobj = GridStack.init({});
            setGrid(gridobj);
            dashboardicon.forEach(element => {
                if(element.value===response.data.menu.icon){
                    setSelectedDashboardicon(element);
                }})
        }, 1000);
    }

    const updateDashboard = () => {
        let dashboardconfig = { "jsonstr_menu": {} };
        dashboardconfig['jsonstr_menu']['icon'] = selectedDashboardicon.value;
        dashboardconfig['jsonstr_menu']['name'] = dashboardname;
        let layout = grid.save();
        layout.forEach(element => {
            delete element['content'];
        });
        dashboardconfig['jsonstr_layout'] = layout;
        dashboardconfig['jsonstr_widget'] = widgetMetadata;
        dashboardconfig['reportId'] = parseInt(editReportId)
        console.log(JSON.stringify(dashboardconfig));
        postUpdateDashboardData(dashboardconfig);

        //updateDashBoard
    }

    const cancelEdit = () => {
        window.location.reload();
    }

    const postUpdateDashboardData = async (dashboarddata) => {
        try {
            const url = Config.baseurl + '/api/updateDashBoard'
            const response = await Axios.post(url, dashboarddata);
            console.log(response);
            setShowModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const addNewWidget = () => {
        // grid.destroy(false);
        // data.layout = [...data.layout,{
        //     x: 0,
        //     y: 0,
        //     h: 3,
        //     w: 4
        // }]
        // setTimeout(() => {
        //     let gridobj = GridStack.init({ margin: 10 });
        //     setGrid(gridobj);
        // }, 1000);
        grid.destroy(false);
        let localdata=data;
        let newlocaldata=JSON.stringify(data);
        let node={
            x: 0,
            y: 0,
            h: 3,
            w: 4,
            id:new Date().getTime()
        }
        localdata.layout=[];
        setData(localdata);
        setTimeout(() => {
            let newlocaldatajson =  JSON.parse(newlocaldata);
            console.log(JSON.stringify(newlocaldatajson));
            newlocaldatajson.layout.push(node);
            console.log(JSON.stringify(newlocaldatajson));
            setData(newlocaldatajson);
            let gridobj = GridStack.init({ margin: 10 });
            setGrid(gridobj);
        }, 1000);
    };

    const addWidgetMetadata = () => {
        console.log('Current Index >>' + currentIndex);
        console.log('Current Title >>' + widgetTitle);
        console.log('Current WidgetType >>' + selectedWidget.value);
        console.log('Current Query >>' + widgetQuery);
        data.widget[currentIndex]['widgetconfig']['type'] = selectedWidget.value;
        setShow(false);
    }

    if (isLoading) {
        return <div className="header">Loading...</div>;
    } else {
        console.log(data);
        return (
            <div>
                <div className="dashboard">
                    <div className="row g-3 mb-2">
                        <div className="col-4">
                            <Select
                                value={selectedEditReport}
                                options={allreports}
                                onChange={event => handleReportChage(event)}
                                getOptionLabel={e => (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                                        <i className={`${e.icon} iconClass`} />
                                        <span style={{ marginLeft: 5 }}>{e.text}</span>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col">
                                 <button type="button" className="btn btn-info btn-md mx-2" onClick={updateDashboard}>
                                    <MaterialDesign.MdEditSquare /> Edit Dashboard
                                </button>
                                 <button type="button" className="btn btn-info btn-md mx-2" onClick={cancelEdit}>
                                    <MaterialDesign.MdCancel /> Cancel Edit
                                </button>
                        </div>
                        
                    </div>

                    <div className="row g-3">
                        <div className="col">
                            <input type="text" className="form-control" id="dashboardname" name="dashboardname" onChange={changeDashboard} placeholder="Dashboard name" aria-label="Dashboard name" defaultValue={data.menu.name} />
                        </div>
                        <div className="col">
                            <Select
                                value={selectedDashboardicon}
                                options={dashboardicon}
                                onChange={handleIconChange}
                                getOptionLabel={e => (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                                        <i className={`${e.icon} iconClass`} />
                                        <span style={{ marginLeft: 5 }}>{e.text}</span>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col">
                            <button type="button" onClick={addNewWidget} className="btn btn-info btn-md mx-2">
                                <MaterialDesign.MdAddchart /> Widget
                            </button>
                        </div>
                    </div>

                </div>
                <ReactErrorBoundary>
                    <div>
                        <div className="grid-stack">
                            {data && data.layout.map((widget, index) => {
                                let inputId = "input_" + index;
                                let widgetId = widget['id'];
                                return (
                                    <div className="grid-stack-item" gs-id={widgetId} gs-x={widget.x} gs-y={widget.y} gs-w={widget.w} gs-h={widget.h}>
                                        <div className="grid-stack-item-content grid-item">
                                            <div className="card">
                                                <div className="card-header">
                                                    {data.widget[widgetId] ? data.widget[widgetId] ['title'] : 'Not Defined'}
                                                    <div className="widgetaction">
                                                        <MaterialDesign.MdDeleteForever className="widgetactionicon" onClick={() => handleDelete({ index })}></MaterialDesign.MdDeleteForever>
                                                        <MaterialDesign.MdEditSquare className="widgetactionicon" onClick={() => handleCanvasShow(index)} />
                                                    </div>

                                                </div>
                                                <div className="card-body">
                                                    <WidgetConfig index={widgetId} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </ReactErrorBoundary>

                <Offcanvas show={show} onHide={handleClose} className="offcanvasModal">
                    <Offcanvas.Header closeButton className="offcanvasHeader">
                        <Offcanvas.Title>Widget Definition</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="offcanvasBody">
                        <form>
                            <div className="mb-3">
                                <label for="widgetTitle" className="form-label">Title:</label>
                                <input onChange={changeWidgetTitle} id="title" name="title" className="form-control" placeholder="Widget Name" defaultValue={data.widget[currentIndex] ? data.widget[currentIndex]['title'] : ''}></input>
                            </div>
                            <div className="mb-3">
                                <label for="widgetType" className="form-label">Widget Type:</label>
                                <Select
                                    value={selectedWidget}
                                    options={widgets}
                                    defaultValue={()=>{getSelectedWidget(currentIndex)}}
                                    onChange={handleChange}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                                            <img src={e.icon} alt="logo" className="widget-logo" />
                                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                                        </div>
                                    )}
                                />

                            </div>
                            <div className="mb-3">
                                <label for="exampleInputPassword1" className="form-label">Query:</label>
                                <textarea onChange={changeWidgetQuery} className="form-control" placeholder="Widget Query" defaultValue={(data.widget[currentIndex] && data.widget[currentIndex]['serviceconfig']) ? data.widget[currentIndex]['serviceconfig']['config']['query'] : ""}></textarea>
                            </div>
                            <button type="button" className="btn btn-info" onClick={() => addWidgetMetadata()}>Attach Widget</button>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>

                <Modal show={showModal} onHide={() => handleCloseModal()} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Response Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Dashboard Saved Successfully!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={() => handleCloseModal()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}
export default LoadEditBuilder;