import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import "./DataTable.css";
import styled from 'styled-components';
import { Button } from "react-bootstrap";
import Config from "../JsonData/config";
import Axios from 'axios';
import { addReportData } from "./ReportData"
const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function RenderDataTable({ tableConfig, widgetId, height }) {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(3);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const filteredItems = data.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );
  createTheme('solarized', {
    text: {
      primary: '#ffff',
      secondary: '#ffff',
    },
    background: {
      default: '#252B48',
    },
    context: {
      background: '#252B48',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
    header: {
      fontSize: '15px'
    }
  }, 'dark');
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  const fetchReportData = async () => {
    if (tableConfig['apt']) {
      const url = Config.baseurl + Config.apidata + "/" + api;
      //console.log("chart api url >> "+url);
      const response = await Axios(url);
      setData(response.data);
      setTimeout(() => {
        //setLoading(false);
      }, 1000);

    } else if (tableConfig['serviceconfig']) {
      const url = Config.baseurl + '/api/getDataForConfig';
      let postdata = { 'serviceconfig': tableConfig['serviceconfig'] };
      const response = await Axios.post(url, postdata);
      //console.log(response);
      setData(response.data.data);
      addReportData(response.data.data);
      if (response.data.data[0]) {
        let row = response.data.data[0];
        let header = []
        for (var key in row) {
          let selectorstr = "row => row." + key;
          let col = {
            name: key,
            selector: eval(selectorstr)
          }
          header.push(col);
        }
        setColumns(header);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);

    }
  }
  const paginationComponentOptions = {
    selectAllRowsItem: true
  };
  useEffect(() => {
    console.log(widgetId);
    console.log(height);
    if (height > 3) {
      let pagelength = (3 + (height - 3) * 2);
      setPageSize(pagelength);
      console.log("pagelength >>" + pagelength);
    }
    fetchReportData();
  }, [])
  if (isLoading) {
    return <div className="header">Loading...</div>;
  }
  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationPerPage={pageSize}
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      paginationComponentOptions={paginationComponentOptions}
      subHeaderComponent={subHeaderComponentMemo}
      // selectableRows
      persistTableHead
      theme="solarized"
    />
  );

}

export default RenderDataTable;