import React, { useState } from "react";
import "./Header.css";
import api from "../../Services/api";
import { Link } from "react-router-dom";
import avatar from "../../Assets/Images/avatar.png";
import { useKeycloak } from "@react-keycloak/web";
import {
  BurgerIcon,
  BurgerIconClosed,
} from "../../Components/IconComponents";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import {
  urmservice,
  userImageUpload,
  mediaFileLink
} from "../../Services/EndPoints"
const Header = ({ children }) => {

  let systemDefinedRoles = ["default-roles-" + process.env.REACT_APP_URM_REALM, "offline_access", "uma_authorization"]
  const { collapsed, toggle } = LayoutState();
  const [avtarImg, setAvtarImg] = useState(null);
  const { keycloak } = useKeycloak();
  
  const getUserRoles = () => {
    if (keycloak && keycloak.tokenParsed && keycloak.tokenParsed.realm_access.roles) {
      getUserProfileImage(keycloak.tokenParsed.email);
      let roles = keycloak.tokenParsed.realm_access.roles;
      let rolesSanitized = [];
      for (let roleindi in roles) {
        if (!(systemDefinedRoles.indexOf(roles[roleindi]) > -1)) {
          rolesSanitized.push(roles[roleindi]);
        }
      }
      rolesSanitized = rolesSanitized.join(",");
      document.getElementById("roleTitleContainer").title = rolesSanitized;
      if (rolesSanitized.length > 20) {
        return rolesSanitized.substring(0, 20) + "...";
      }
      return rolesSanitized;
    }
    else {
      return "NA";
    }
  }

  const getUserProfileImage = async (email) => {
    let headers = {
      "content-type": "application/json",
    };
    try {
      const response = await api.getData(urmservice + userImageUpload + "/" + email, {}, headers);
      setAvtarImg(urmservice + mediaFileLink + "/" + response.data.user_image.id)
    }
    catch (e) {
      setAvtarImg(avatar)
    }

  }
  return (
    <>
      <div className={`top__nav ${collapsed ? " collapsed" : null}`}>
        <div className="top__nav-wrapper">
          <div className="top-menu" onClick={() => toggle(!collapsed)}>
            <span>
              {!collapsed ? (
                <BurgerIcon width="1.6em" height="1.6em" />
              ) : (
                <BurgerIconClosed width="1.6em" height="1.6em" />
              )}
              <span className="ml-2">Menu</span>
            </span>
          </div>
          <div className="center-box">
            {/* functional area dropdown will come in place of childeren */}
            {children}
          </div>
          <div className="top__nav-right">
            <div className="user-details">
              <span className="user-name">{(keycloak.idTokenParsed && keycloak.idTokenParsed.name) ? keycloak.idTokenParsed.name : "NA"}</span>
              <span className="user-role" id="roleTitleContainer">{getUserRoles()}</span>
            </div>
            <div className="profile">
             
                <img src={avtarImg} alt="" />
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
