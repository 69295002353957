import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import Config from "../JsonData/config";
import Axios from 'axios';
import { ChartSettings } from "../JsonData/ChartSettings"
import { addReportData } from "./ReportData"
function RenderChart({ chartconfig, widgetId, height }) {
  //console.log('chartlib')
  const api = chartconfig.api;
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    //window.location.reload(false);
    const fetchReportData = async () => {
      //console.log('Chart API >>'+JSON.stringify(chartconfig))
      if (chartconfig['api']) {
        const url = Config.baseurl + Config.apidata + "/" + api;
        //console.log("chart api url >> "+url);
        const response = await Axios(url);
        setData(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);

      } else if (chartconfig['serviceconfig']) {
        const url = Config.baseurl + '/api/getDataForConfig';
        let postdata = { 'serviceconfig': chartconfig['serviceconfig'] };
        const response = await Axios.post(url, postdata);
        //console.log(response);
        setData(response.data.data);
        addReportData(widgetId,response.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);

      }
    }
    fetchReportData();
  }, [])

  const extractLabelValues = () => {
    const charttype = chartconfig.type;
    let labels = [];
    let values = [];
    let ismultiple = false;
    if (data && Array.isArray(data[0])) {
      ismultiple = true;
    }
    if (ismultiple) {
      data[0].forEach(element => {
        labels.push(element.label);
      });

      data.forEach(element => {
        let value = [];
        element.forEach(element1 => {
          value.push(element1.value);
        });
        values.push(value);
      });
    } else {
      data.forEach(element => {
        labels.push(element.label);
        values.push(element.value);
      });
    }
    return { "label": labels, "value": values, "ismultiple": ismultiple };
  }

  const extractpiedata = () => {
    let values = [];
    let ismultiple = false;
    //console.log("data "+JSON.stringify(data));
    if (data && JSON.stringify(data) != '{}') {
      data.forEach(element => {
        values.push({ value: element.value, name: element.label });
      });
    }
    return { "label": [], "value": values, "ismultiple": ismultiple };
  }
  function getChartConfig() {
    // const charttype = chartconfig.type.toLowerCase();

    const charttype = chartconfig['widgetconfig'].type.toLowerCase().split(":")[1];
    let lablevalues = null;
    switch (charttype) {
      case "bar":
        lablevalues = extractLabelValues();
        break;
      case "line":
        lablevalues = extractLabelValues();
        break;
      case "doughnut":
        lablevalues = extractpiedata();
        break;
      case "pie":
        lablevalues = extractpiedata();
        break;
      default:
        break;
    }
    let chartoption = JSON.parse(JSON.stringify(ChartSettings[charttype]['option']));
    if (chartoption['xAxis'] && chartoption['xAxis']['data']) {
      chartoption['xAxis']['data'] = lablevalues.label;
    }
    if (chartoption['legend'] && chartoption['legend']['data']) {
      chartoption['legend']['data'] = chartconfig.names;
    }

    if (lablevalues['ismultiple']) {
      let serieses = [];
      lablevalues.value.forEach((element, index) => {
        const seriesname = chartconfig.names[index] ? chartconfig.names[index] : chartconfig.names[0];
        let series = JSON.parse(JSON.stringify(ChartSettings[charttype]['series']));
        series['name'] = seriesname;
        series['data'] = element;
        series['type'] = charttype;
        series['emphasis'] = {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        };
        serieses.push(series);
      });
      chartoption['series'] = serieses;
    } else {
      let series = JSON.parse(JSON.stringify(ChartSettings[charttype]['series']));
      // series['name']=chartconfig.names[0];
      series['data'] = lablevalues.value;
      series['type'] = charttype === 'doughnut' ? 'pie' : charttype;
      let seriess = [];
      seriess.push(series);
      chartoption['series'] = series;
    }
    //console.log(JSON.stringify(chartoption));
    return chartoption;
  }
  if (isLoading) {
    return <div className="header">Loading...</div>;
  }
  return <ReactEcharts option={getChartConfig()} style={{ height: "100%", width: "100%" }} />;
}
export default RenderChart;