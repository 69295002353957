import React, { useEffect, useState, useRef } from "react";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "./Builder.css";
import Axios from 'axios';
import * as MaterialDesign from "react-icons/md"
import WidgetConfig from "./WidgetConfig";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select, { components } from "react-select";
import linechartimg from "../Assets/Images/linechart.png";
import datatableimg from "../Assets/Images/datatable.png";
import barchrtimg from "../Assets/Images/barchart.png";
import doughnutchartimg from "../Assets/Images/doughnutchart.png";
import piechartimg from "../Assets/Images/piechart.png";
import { setMenuInfo, clearDashboardInfo, getDashboardInfo } from "./Metadata";
import Config from "../JsonData/config";;
import Modal from 'react-bootstrap/Modal';
import ReactErrorBoundary from "../Services/ReactErrorBoundary";

const widgets = [
    { value: "chart:bar", label: "Bar Chart", icon: barchrtimg },
    { value: "chart:line", label: "Line Chart", icon: linechartimg },
    { value: "chart:doughnut", label: "Doughnut Chart", icon: doughnutchartimg },
    { value: "chart:pie", label: "Pie Chart", icon: piechartimg },
    { value: "table:datatable", label: "Data Table", icon: datatableimg }
];

const dashboardicon = [
    { value: "fa-solid fa-chart-simple", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-simple' },
    { value: "fa-solid fa-chart-pie", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-pie' },
    { value: "fa-solid fa-chart-line", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-chart-line' },
    { value: "fa-brands fa-windows", text: 'Dashboard Menu Icon', icon: 'fa-brands fa-windows' },
    { value: "fa-solid fa-sliders", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-sliders' },
    { value: "fa-solid fa-layer-group", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-layer-group' },
    { value: "fa-solid fa-table", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-table' },
    { value: "fa-solid fa-headphones", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-headphones' },
    { value: "fa-solid fa-city", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-city' },
    { value: "fa-solid fa-cloud-moon-rain", text: 'Dashboard Menu Icon', icon: 'fa-solid fa-cloud-moon-rain' }
];

const dashboardiconOption = (props) => (
    <components.Option {...props} className="widget-option">
        <span className={props.data.icon}></span>
        {props.data.icon}
    </components.Option>
);

const widgetOption = (props) => (
    <components.Option {...props} className="widget-option">
        <img src={props.data.icon} alt="logo" className="widget-logo" />
        {props.data.label}
    </components.Option>
);

const Builder = () => {
    clearDashboardInfo();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedDashboardicon, setSelectedDashboardicon] = useState(dashboardicon[0]);
    const [selectedWidget, setSelectedWidget] = useState(widgets[0]);
    const [dashboardname, setDashboardname] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [widgetTitle, setWidgetTitle] = useState(null);
    const [widgetQuery, setWidgetQuery] = useState(null);
    const [allreports, setAllreports] = useState([]);
    const [selectedEditReport, setSelectedEditReport] = useState(null);
    const [editReportId, setEditReportId] = useState(null);
    const getSelectedWidget = (value) => {
        let selwidget = widgets[0];
        widgets.forEach(element => {
            if (element['value'] === value) {
                selwidget = element;
            }
        });
        return selwidget;
    }
    const handleCloseModal = () => {
        setShowModal(false)
        setTimeout(() => {
            window.location.reload();
        }, 10);
    };

    const cancelEdit = () => {
        window.location.reload();
    }

    const addWidgetMetadata = () => {
        console.log('Current Index >>' + currentIndex);
        console.log('Current Title >>' + widgetTitle);
        console.log('Current WidgetType >>' + selectedWidget.value);
        console.log('Current Query >>' + widgetQuery);
        let apiconfig = { "config": { "query": widgetQuery }, "type": "GET" }
        let widget = { "title": widgetTitle, "serviceconfig": apiconfig, "widgetconfig": { "type": selectedWidget.value } }
        let widgetMetadatalocal = widgetMetadata;
        widgetMetadatalocal[currentIndex] = widget;
        console.log(JSON.stringify(widgetMetadatalocal));
        setWidgetMetadata(widgetMetadatalocal);
        setShow(false);
    }

    const handleChange = (value) => {
        setSelectedWidget(value);
    };

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={selectedWidget.icon} alt="s-logo" className="selected-logo" />
            {children}
        </components.SingleValue>
    );
    const handleIconChange = (value) => {
        setSelectedDashboardicon(value);
    };

    const handleReportChage = (value) => {
        setSelectedEditReport(value);
        setTimeout(() => {
            loadEditDashBoard(value);
        }, 50);

    };

    const SingleIconValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <i className={props.data.icon}></i>
            {props.data.icon}
        </components.SingleValue>
    );
    const [widgetMetadata, setWidgetMetadata] = useState({});

    const [widgetArray, setWidgetArray] = useState([{
        x: 0,
        y: 0,
        h: 3,
        w: 4,
        id: 1
    }])
    const [state, setState] = useState({
        count: 0,
        info: "",
        items: [
            { x: 2, y: 1, h: 2 },
            { x: 2, y: 4, w: 3 },
            { x: 4, y: 2 },
            { x: 3, y: 1, h: 2 },
            { x: 0, y: 6, w: 2, h: 2 },
        ],
    });
    const [grid, setGrid] = useState(null);

    const handleDelete = (id) => {
        grid.removeWidget("div[gs-id='" + id.index + "']");
    }

    const handleCanvasShow = (index) => {
        console.log('Current Index >>' + index);
        if(widgetMetadata[index]){
            setWidgetTitle(widgetMetadata[index]['title']);
            setSelectedWidget(getSelectedWidget(widgetMetadata[index]['widgetconfig']['type']));
            setWidgetQuery(widgetMetadata[index]['serviceconfig']['config']['query'])
        }else{
            setWidgetTitle(null);
            setSelectedWidget(widgets[0]);
            setWidgetQuery(null)
        }
        setCurrentIndex(index);
        setShow(true)
    };

    const changeDashboard = event => {
        let dashboardname = event.target.value;
        setDashboardname(dashboardname);
    };

    const changeWidgetTitle = event => {
        setWidgetTitle(event.target.value);
    };

    const changeWidgetQuery = event => {
        setWidgetQuery(event.target.value)
    };

    const updateDashboard = () => {
        let dashboardconfig = { "jsonstr_menu": {} };
        dashboardconfig['jsonstr_menu']['icon'] = selectedDashboardicon.value;
        dashboardconfig['jsonstr_menu']['name'] = dashboardname;
        let layout = grid.save();
        layout.forEach(element => {
            delete element['content'];
        });
        dashboardconfig['jsonstr_layout'] = layout;
        dashboardconfig['jsonstr_widget'] = widgetMetadata;
        dashboardconfig['reportId'] = parseInt(editReportId)
        console.log(JSON.stringify(dashboardconfig));
        postUpdateDashboardData(dashboardconfig);

        //updateDashBoard
    }

    const postUpdateDashboardData = async (dashboarddata) => {
        try {
            const url = Config.baseurl + '/api/updateDashBoard'
            const response = await Axios.post(url, dashboarddata);
            console.log(response);
            setShowModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const saveDashboard = () => {
        let dashboardconfig = { "jsonstr_menu": {} };
        dashboardconfig['jsonstr_menu']['icon'] = selectedDashboardicon.value;
        dashboardconfig['jsonstr_menu']['name'] = dashboardname;
        let layout = grid.save();
        layout.forEach(element => {
            delete element['content'];
        });
        dashboardconfig['jsonstr_layout'] = layout;
        dashboardconfig['jsonstr_widget'] = widgetMetadata;
        console.log(JSON.stringify(dashboardconfig));
        postDashboardData(dashboardconfig);
    }

    const postDashboardData = async (dashboarddata) => {
        try {
            const url = Config.baseurl + '/api/createDashBoard'
            const response = await Axios.post(url, dashboarddata);
            console.log(response);
            setShowModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const addNewWidget = () => {
        setWidgetArray([
            ...widgetArray,
            {
                x: 0,
                y: 0,
                h: 3,
                w: 4,
                id:new Date().getTime()
            }
        ])
        setTimeout(() => {
            grid.destroy(false);
            let gridobj = GridStack.init({ margin: 10 });
            setGrid(gridobj);
        }, 100);
    };


    useEffect(() => {
        fetchMenus();
        if(grid!=null){
            grid.destroy(false);
        }
        let gridobj = GridStack.init({ margin: 10 });
        setGrid(gridobj);
    }, [widgetArray]);

    const fetchMenus = async () => {
        const url = Config.baseurl + Config.menu;
        console.log("menu url >> " + url);
        const response = await Axios(url);
        let reports = [];
        response.data.forEach(element => {
            reports.push({ value: element["reportId"], text: element["display"], icon: element["icon"] })
        });
        setAllreports(reports);
    }
    const loadEditDashBoard = async (value) => {
        console.log(value.icon);
        let reportId = value.value;
        const url = Config.baseurl + Config.dashboard + "/" + reportId;
        console.log("dashboard url >> " + url);
        const response = await Axios(url);
        const reportconfig = response.data;
        grid.destroy(false);
        setDashboardname(value.text);
        //setWidgetArray(reportconfig['layout']);
        setWidgetArray([])
        console.log(widgetMetadata);
        console.log(reportconfig['widget']);
        setWidgetMetadata(reportconfig['widget']);
        dashboardicon.forEach(element => {
            if (element['icon'] === value.icon) {
                setSelectedDashboardicon(element);
            }
        });
        setEditReportId(reportId);
        setTimeout(() => {
            setWidgetArray(reportconfig['layout']);
        }, 1000);
    }


    return (
      <div>
        <div className="dashboard">
          <div className="row g-3 mb-2">
            <div className="col-4">
              <Select
                className="react-select-container"
                classNamePrefix="custom-select"
                value={selectedEditReport}
                options={allreports}
                onChange={(event) => handleReportChage(event)}
                getOptionLabel={(e) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#000",
                    }}
                  >
                    <i className={`${e.icon} iconClass`} />
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
            <div className="col button-style">
              {editReportId ? (
                <button
                  type="button"
           
                  className="btn btn-primary btn-md mx-2"
                  onClick={updateDashboard}
                >
                  <MaterialDesign.MdEditSquare /> Edit Dashboard
                </button>
              ) : (
                ""
              )}
              {editReportId ? (
                <button
                  type="button"
                  className="btn btn-primary btn-md mx-2"
                  onClick={cancelEdit}
                >
                  <MaterialDesign.MdCancel /> Cancel Edit
                </button>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col">
                    </div> */}
          </div>

          <div className="row g-3">
            <div className="col">
              <input
                type="text"
                className="form-control form__input"
                id="dashboardname"
                name="dashboardname"
                onChange={changeDashboard}
                placeholder="Dashboard name"
                // aria-label="Dashboard name"
                defaultValue={dashboardname}
              />
            </div>
            <div className="col">
              <Select
                className="react-select-container"
                classNamePrefix="custom-select"
                value={selectedDashboardicon}
                options={dashboardicon}
                onChange={handleIconChange}
                getOptionLabel={(e) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#000",
                    }}
                  >
                    <i className={`${e.icon} iconClass`} />
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
            <div className="col">
              <div className="form-check form-switch">
                <input
                  className="form-check-input autofreshCheck"
                  type="checkbox"
                />
                <label
                  className="form-check-label autofreshLabel"
                  for="flexSwitchCheckChecked"
                >
                  Auto-Refresh
                </label>
              </div>
            </div>
            <div className="col button-style">
              <button
                type="button"
                onClick={addNewWidget}
                className="btn btn-primary btn-md mx-2 "
              >
                <MaterialDesign.MdAddchart /> Widget
              </button>

              {editReportId == null ? (
                <button
                  type="button"
                  onClick={saveDashboard}
                  className="btn btn-primary btn-md"
                >
                  Create Dashboard
                </button>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col">
                    </div> */}
          </div>
        </div>
        <ReactErrorBoundary>
          <div>
            <div className="grid-stack">
              {widgetArray?.map((widget, i) => {
                let index = widget["id"];
                let inputId = "input_" + index;
                //console.log(widgetArray);
                return (
                  <div
                    className="grid-stack-item"
                    gs-id={index}
                    gs-x={widget.x}
                    gs-y={widget.y}
                    gs-w={widget.w}
                    gs-h={widget.h}
                  >
                    <div className="grid-stack-item-content grid-item">
                      <div className="card">
                        <div className="card-header">
                          {widgetMetadata[index]
                            ? widgetMetadata[index]["title"]
                            : "Not Defined"}
                          <div className="widgetaction">
                            <MaterialDesign.MdDeleteForever
                              className="widgetactionicon"
                              onClick={() => handleDelete({ index })}
                            ></MaterialDesign.MdDeleteForever>
                            <MaterialDesign.MdEditSquare
                              className="widgetactionicon"
                              onClick={() => handleCanvasShow(index)}
                            />
                          </div>
                        </div>
                        <div className="card-body">
                          <WidgetConfig
                            index={index}
                            type={
                              widgetMetadata[index]
                                ? widgetMetadata[index]["widgetconfig"]["type"]
                                : "NA"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ReactErrorBoundary>

        <Offcanvas show={show} onHide={handleClose} className="offcanvasModal">
          <Offcanvas.Header closeButton className="offcanvasHeader">
            <Offcanvas.Title>Widget Definition</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvasBody">
            <form>
              <div className="mb-3">
                <label for="widgetTitle" className="form-label">
                  Title:
                </label>
                <input
                  onChange={changeWidgetTitle}
                  value={widgetTitle}
                  id="title"
                  name="title"
                  className="form-control"
                  placeholder="Widget Name"
                  defaultValue={
                    widgetMetadata[currentIndex]
                      ? widgetMetadata[currentIndex]["title"]
                      : ""
                  }
                ></input>
              </div>
              <div className="mb-3">
                <label for="widgetType" className="form-label">
                  Widget Type:
                </label>
                <Select
                  value={selectedWidget}
                  options={widgets}
                  onChange={handleChange}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                      }}
                    >
                      <img src={e.icon} alt="logo" className="widget-logo" />
                      <span style={{ marginLeft: 5 }}>{e.label}</span>
                    </div>
                  )}
                />
              </div>

              {/* <div className="mb-3">
                            <label for="exampleInputEmail1" className="form-label">Widget Title:</label>
                            <input type="type" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                        </div> */}
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Query:
                </label>
                <textarea
                  onChange={changeWidgetQuery}
                  className="form-control"
                  placeholder="Widget Query"
                  defaultValue={
                    widgetMetadata[currentIndex] &&
                    widgetMetadata[currentIndex]["serviceconfig"]
                      ? widgetMetadata[currentIndex]["serviceconfig"]["config"][
                          "query"
                        ]
                      : ""
                  }
                ></textarea>
              </div>
              <button
                type="button"
                className="btn btn-info"
                onClick={() => addWidgetMetadata()}
              >
                Attach Widget
              </button>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showModal}
          onHide={() => handleCloseModal()}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Response Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Dashboard Saved Successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={() => handleCloseModal()}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );

}
export default Builder;