import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Button } from "react-bootstrap";
import "./Table.css";
import apiData from "../JsonData/apiData";
import Config from "../JsonData/config";
import Axios from 'axios';
function RenderTable({tableConfig}){
    const [data, setData] = useState();
    function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
    
        const filename = 'export.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="btn btn-secondary btn-sm">Export</Button>;

    const customStyles = {
        rows: {
            style: {
                backgroundColor: 'var(--secondary-color)',
                color:'#fff'
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                backgroundColor: '#627ca4',
                color:'#fff',
                fontSize:'15px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                border:'rgba(0,0,0,.12)'
            },
        },
    };
    console.log('>>> Table Config'+JSON.stringify(tableConfig));
    
    let columns = [];
    columns.forEach(element => {
        element['selector'] = eval(element['selector']);
    });
    //console.log(columns);
    //const api = tableConfig.api;
    //const data = apiData[api];
    //console.log(data);
    const paginationComponentOptions = {
        selectAllRowsItem: true
    };
    console.log('Table Rendering');
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);
    const fetchReportData=async()=>{
        const url = Config.baseurl+Config.apidata+"/"+api;
        console.log("api url >> "+url);
        const response=await Axios(url);
        setData(response.data);    
      }
    useEffect(() => {
    console.log(data);
    fetchReportData();
    }, [])
    if(data){
    return (
        <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles} 
            pagination paginationComponentOptions={paginationComponentOptions} 
            // actions={actionsMemo}
        />
    );
    }else{
        return <div className="header">Loading...</div>;
    }
}
export default RenderTable;