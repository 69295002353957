import { createContext, useContext, useState } from "react";

const MapMarker = createContext();

const MapMarkerContext = ({ children }) => {
  const [markerId, setMarkerId] = useState(0); //locationId
  const [areaId, setAreaId] = useState(1); //areaId
  const [refresh, setRefresh] = useState(false);
  return (
    <MapMarker.Provider
      value={{ markerId, setMarkerId, areaId, setAreaId, refresh, setRefresh }}
    >
      {children}
    </MapMarker.Provider>
  );
};

export const MarkerState = () => {
  return useContext(MapMarker);
};

export default MapMarkerContext;
