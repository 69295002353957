import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Signout from "./Signout/Signout";
import Router from "../Routes/Router";
import "./Layout.css"

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <div>
      <Sidebar collapsed={collapsed} toggle={toggle} />
      <div
        className={`${collapsed ? "main__layout-collapsed" : "main__layout"}`}
      >
        <Header collapsed={collapsed} toggle={toggle} />

        <div className="content">
          <Router />
          <div>
           <Signout/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
