import { Routes, Route, Navigate } from "react-router-dom";
import Devices from "../Pages/Devices/Devices";
import ReportBodyRender from "../Pages/ReportBodyRender/ReportBodyRender"
import DashboardHome from "../Pages/DashboardHome/DashboardHome"
import Builder from "../Builder/Builder";
import BuilderEdit from "../Builder/BuilderEdit";
const Router = () => {
  return (
    <Routes>
      {/* <Route
        path="/"
        element={<Navigate to="/dashboard/:reportId" element={<ReportBodyRender />} />}
      /> */}
      <Route path="/dashboard" element={<DashboardHome />} />
      <Route path="/dashboard/:reportId" element={<ReportBodyRender />} />
      <Route path='/devices' element={<Devices/>}/>
      <Route path='/builder' element={<Builder/>}/>
      <Route path='/dashbuilderedit/:reportId' element={<BuilderEdit/>}/>
    </Routes>
  );
};

export default Router;
